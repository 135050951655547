import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";

export default class JtpAuthNavItem extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  getLoginLogout(props: any) {
    if (this.props.isAuthenticated) {
      let avatarTag = (
        <svg
          width="48px"
          height="48px"
          viewBox="0 0 16 16"
          className="bi bi-person-circle text-light"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z" />
          <path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          <path
            fillRule="evenodd"
            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
          />
        </svg>
      );
      if (props.user.avatar) {
        const avatar = (window.URL || window.webkitURL).createObjectURL(
          props.user.avatar
        );
        avatarTag = (
          <img
            alt="Avatar"
            style={{ margin: "5px 0", width: "48px" }}
            className="rounded-circle"
            src={avatar}
          />
        );
      }
      return (
        <UncontrolledDropdown tag="li" className="nav-item">
          <DropdownToggle tag="a" className="nav-link">
            <span className="text-light d-none d-md-inline">
              Hello, {props.user.givenName}! &nbsp;
            </span>
            {avatarTag}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={props.authButtonMethod}>
              Sign Out
            </DropdownItem>
            <DropdownItem>
              <Link to="/Manage">Admin</Link>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }

    return (
      <NavItem>
        <Button
          onClick={props.authButtonMethod}
          className="btn-link btn-light nav-link border-0"
          color="link"
        >
          Sign In
        </Button>
      </NavItem>
    );
  }

  render() {
    return (
      <Nav className="justify-content-end">
        {this.getLoginLogout(this.props)}
      </Nav>
    );
  }
}
