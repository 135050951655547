import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Layout from "./components/Layout";
import withAuthProvider, { AuthComponentProps, AuthContext } from "./AuthProvider";
import { QuoteWorksheetPage } from "./pages/QuoteWorksheet";
import { ManageTablesPage } from "./pages/ManageTables";
import { LoginPage } from "./pages/LoginPage";


class App extends React.Component<AuthComponentProps> {
  render() {
    return (
      <AuthContext.Consumer>
        {
          context => (
            <Router>
              <Layout {...this.props}>
                <Switch>                  
                  {/* <Route path="/" exact component={QuoteWorksheetPage} />
                  <Route path="/manage" exact component={ManageTablesPage} /> */}
                  <Route path="/" exact> 
                    { !context.isAuthenticated ? <Redirect to="/login" /> : <QuoteWorksheetPage />}
                  </Route>
                  <Route path="/manage" exact>
                    { !context.isAuthenticated ? <Redirect to="/login" /> : <ManageTablesPage />}
                  </Route>
                  <Route path="/login" exact>
                  { context.isAuthenticated ? <Redirect to="/" /> : <LoginPage />}
                  </Route>
                  <Route>
                    <h1>Whoops - missing page</h1>
                  </Route>
                </Switch>
              </Layout>
            </Router>
          )
        }
      </AuthContext.Consumer>
    );
  }
}

export default withAuthProvider(App);