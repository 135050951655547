export const config = {
  apiBaseUrl: 'https://svc.tri-county-metals.stalwart.cloud/api',
  domain: 'stalwartenterprises.com',
    tenantId: 'd0a3484d-280a-4d56-bfbd-43a0ed575101',
    authority: "https://login.microsoftonline.com/",
    appId: 'c8cfda85-2855-4715-bdbb-b859999c6c2e',
    redirectUri: 'https://tri-county-metals.stalwart.cloud',
    scopes: [
        'user.read',
        'Profile'
    ]
}