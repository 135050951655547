import { CalculatedValues, PanelDetail, PanelOrderLine } from "./types";

// panelProfileIds are:
// 1: 1" TCM Lok
// 2: 1.5" SL
// 3: 1.5" TCM Lok
// 4: 1.75" SS Lock
// 5: 2" MS Lok
// 6: 5v Crimp
// 7: PBR2
// 8: PBR5
// 9: Ultra Rib

export default class Calculate {
  static totalWeight(linearFeet: number, weightOfLinearFoot: number) {
    return linearFeet * weightOfLinearFoot;
  }

  static stealthBondRingshank(boards: number): number {
    return Math.ceil((boards * 12 * 5) / 7200);
  }

  static stealthBondPancakes(
    vals: CalculatedValues,
    ridge: number,
    hip: number,
    endwall: number,
    transition: number,
    gable: number,
    sidewall: number
  ): number {
    return this.trimScrews(
      vals,
      ridge,
      hip,
      endwall,
      transition,
      gable,
      sidewall
    );
  }

  static stealthBondRivets(ridge: number, sidewall: number): number {
    return this.rivets(ridge, sidewall);
  }

  static stealthBondZFlashing(
    ridge: number,
    hip: number,
    endwall: number,
    transition: number,
    gable: number,
    sidewall: number
  ): number {
    return this.zFlashing(ridge, hip, endwall, transition, gable, sidewall);
  }
  static peelStickUnderlayment(squareFeet: number): number {
    return Math.ceil(squareFeet / 165);
  }
  static tackTape(
    ridge: number,
    hip: number,
    endwall: number,
    transition: number,
    gable: number,
    sidewall: number,
    valley: number
  ): number {
    return Math.ceil(
      ((this.zFlashing(ridge, hip, endwall, transition, gable, sidewall) +
        this.panelStarters(valley, transition)) *
        10) /
        50
    );
  }

  static titebond(
    ridge: number,
    hip: number,
    endwall: number,
    transition: number,
    gable: number,
    sidewall: number
  ): number {
    return Math.ceil(
      (this.zFlashing(ridge, hip, endwall, transition, gable, sidewall) * 10) /
        12
    );
  }

  static clips(linearInches: number, detail: PanelDetail): number {
    if (detail.panelProfileId === 2) {
      return Math.ceil(linearInches * 24 + 5);
    }

    return 0;
  }

  static rivets(ridge: number, sidewall: number): number {
    return ridge * 22 + sidewall * 11;
  }
  static pancakeScrews(linearInches: number, screwSpacing: number): number {
    return this.panelScrews(linearInches, screwSpacing);
  }
  static eaveCleats(gable: number, drip: number): number {
    return gable + drip;
  }
  static panelStarters(valley: number, transition: number): number {
    return valley * 2 + transition;
  }

  static zFlashing(
    ridge: number,
    hip: number,
    endwall: number,
    transition: number,
    gable: number,
    sidewall: number
  ): number {
    return (ridge + hip) * 2 + (endwall + transition + gable + sidewall);
  }

  static flex_o_vent(ridge: number, detail: PanelDetail): number {
    const appliesTo: number[] = [6, 7, 8, 9];
    if (appliesTo.indexOf(detail.panelProfileId) > -1) {
      return ridge * 10;
    }

    return 0;
  }

  static insideClosures(
    drip: number,
    transition: number,
    detail: PanelDetail
  ): number {
    if (detail.panelProfileId === 6) {
      return drip * 5 + transition * 5;
    }
    let appliesTo: number[] = [7, 8, 9];
    if (appliesTo.indexOf(detail.panelProfileId) > -1) {
      return (drip * 10) / 3 + (transition * 10) / 3;
    }

    return 0;
  }

  static outsideClosures(
    ridge: number,
    endwall: number,
    transition: number,
    detail: PanelDetail
  ): number {
    let appliesTo: number[] = [7, 9];
    if (appliesTo.indexOf(detail.panelProfileId) > -1) {
      return (
        Math.ceil((ridge * 10) / 13) * 2 + // w34
        Math.ceil((endwall * 10) / 13) + // w36
        Math.ceil((transition * 10) / 3) // w38
      );
    }

    if (detail.panelProfileId === 8) {
      return Math.ceil(((ridge * 10) / 13) * 2); // w34
    }

    if (detail.panelProfileId === 6) {
      // w32 + w35 + w37
      return (
        Math.ceil((ridge * 10) / 2) * 2 + // w32
        (endwall * 10) / 2 + // w35
        (transition * 10) / 2 // w37
      );
    }

    return 0;
  }
  static expandingFoam(valley: number, detail: PanelDetail): number {
    const appliesTo: number[] = [6, 7, 8, 9];
    if (appliesTo.indexOf(detail.panelProfileId) > -1) {
      return valley;
    }

    return 0;
  }

  static tackyTapeTrim(
    gable: number,
    sideWall: number,
    detail: PanelDetail
  ): number {
    const appliesTo: number[] = [6, 7, 8, 9];
    if (appliesTo.indexOf(detail.panelProfileId) > -1) {
      return Math.ceil((gable * 10 + sideWall * 10) / 50);
    }

    return 0;
  }
  static tackyTapePanelLaps(linearFeet: number, detail: PanelDetail): number {
    const appliesTo: number[] = [6, 7, 8, 9];
    if (appliesTo.indexOf(detail.panelProfileId) > -1) {
      return Math.ceil(linearFeet / 50);
    }

    return 0;
  }

  static purlinNails(purlins: number, detail: PanelDetail): number {
    const appliesTo: number[] = [1, 5, 6, 7, 8, 9];
    if (appliesTo.indexOf(detail.panelProfileId)) {
      return Math.ceil(purlins * 37);
    }

    return 0;
  }

  static purlinScrews(purlins: number, detail: PanelDetail): number {
    const appliesTo: number[] = [1, 5, 6, 7, 8, 9];
    if (appliesTo.indexOf(detail.panelProfileId)) {
      return Math.ceil(purlins * 14);
    }

    return 0;
  }

  static syntheticUnderlayment(squareFeet: number): number {
    return Math.ceil(squareFeet / 750);
  }

  static doubleBubble(squareFeet: number): number {
    return Math.ceil(squareFeet / 500);
  }

  static sixInchPeelSeal(hip: number, detail: PanelDetail): number {
    const appliesTo: number[] = [6, 7, 9];
    if (appliesTo.indexOf(detail.panelProfileId) > -1) {
      return Math.ceil((hip * 10) / 33);
    }

    return 0;
  }

  static panelScrews(linearInches: number, screwSpacing: number) {
    return Math.ceil(linearInches / screwSpacing / 250);
  }

  static trimScrews(
    vals: CalculatedValues,
    ridge: number,
    hip: number,
    endwall: number,
    transition: number,
    gable: number,
    sidewall: number
  ): number {
    return (
      ((ridge + hip) * 10 * 12) / 4.5 +
      ((endwall + transition) * 10 * 12) / 9 +
      ((gable + sidewall) * 10 * 12) / 24
    );
  }

  static lapScrews(
    detail: PanelDetail,
    panelEntries: PanelOrderLine[]
  ): number {
    const appliesTo: number[] = [6, 7, 8, 9];
    if (appliesTo.indexOf(detail.panelProfileId) > -1) {
      const laps = panelEntries.reduce(
        (accumulator: number, entry: PanelOrderLine) => {
          return (
            accumulator +
            Math.ceil((entry.lengthInInches / 24 + 1) * entry.numPanels)
          );
        },
        0
      );
      return laps;
    }

    return 0;
  }

  static purlins(detail: PanelDetail, entries: PanelOrderLine[]): number {
    const appliesTo: number[] = [6, 7, 9];
    if (appliesTo.indexOf(detail.panelProfileId) > -1) {
      return Math.ceil(
        entries.reduce((accumulator, entry) => {
          const boardsPerRow =
            (entry.numPanels * (detail.boardSpacing / 8)) / 12;
          const rows =
            2 + Math.floor(entry.lengthInInches / detail.boardSpacing);
          const boards = boardsPerRow * rows;
          return accumulator + boards;
        }, 0)
      );
    }

    return 0;
  }

  static screws(linearInches: number, screwSpacing: number): number {
    return Math.ceil(linearInches / screwSpacing);
  }

  static squareFeet(linearInches: number, panelWidth: number): number {
    return (linearInches / 12) * (panelWidth / 12);
  }

  static linearFeet(linearInches: number): number {
    return linearInches / 12;
  }

  static linearInches(entries: PanelOrderLine[]): number {
    return entries.reduce((accumulator: number, entry: PanelOrderLine) => {
      return (
        accumulator + (entry.numFeet * 12 + entry.numInches) * entry.numPanels
      );
    }, 0);
  }
}
