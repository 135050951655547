import { PanelDetail, PanelProfile, PanelWeightMap, WeightProfile } from "../types";

export default class TcmQuotingService {
  private _apiBasePath: string;

  constructor(baseApiPath: string) {
    this._apiBasePath = baseApiPath;
  }

  //this class is bassically intended to wrap up the web requests to the Tcm Quoting Api Service.  

  public getPanelWeightMatrixDetails() : Promise<PanelDetail[]> {    
    return fetch(this._apiBasePath + "/panels")
      .then((response) => response.json())
      .then((data) => data.map(function (item: any) {
        return {
          id: item.id,
          panelProfileId: item.panelProfileId,
          weightProfileId: item.weightProfileId,
          profileName: item.profileName,
          panelWidth: item.panelWidth,
          gaugeName: item.gaugeName,
          weightBySqFt: item.weightBySqFt,
          screwSpacing: item.screwSpacing,
          boardSpacing: item.boardSpacing
        } as PanelDetail;
      }));
  }

  public getPanelProfiles(): Promise<PanelProfile[]> {
    return fetch(this._apiBasePath + "/panelprofiles")
      .then((response) => response.json())
      .then((data) => data.map(function (item: any) {
        return {
          id: item.id,
          profileName: item.profileName,
          panelWidth: item.panelWidth,
          boardSpacing: item.boardSpacing
        } as PanelProfile;
      }));
  }

  public getWeightProfiles(): Promise<WeightProfile[]> {
    return fetch(this._apiBasePath + "/weightprofiles")
      .then((response) => response.json())
      .then((data) => data.map(function (item: any) {
        return {
          id: item.id,
          gaugeName: item.gaugeName,
          weightBySqFt: item.weightBySqFt
        } as WeightProfile;
      }));
  }

  public createPanelProfile(item: PanelProfile) {
    return fetch(this._apiBasePath + "/panelprofiles", {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item)
    });
    // right now.  I've got no error handling. boo hoo. 
  }

  public createWeightProfile(item: WeightProfile) {
    return fetch(this._apiBasePath + "/weightprofiles", {
      method: "post",      
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item)
    });
  }

  public createPanelWeightMatrixItem(item: PanelWeightMap) {
    return fetch(this._apiBasePath + "/panels", {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item)
    });
  }

  public deleteWeightProfile(id: number) {
    return fetch(this._apiBasePath + "/weightProfiles/" + id, {
      method: "delete",
    });
  }
    

  public deletePanelProfile(id: number) {
    return fetch(this._apiBasePath + "/panelProfiles/" + id, {
      method: "delete",
    });
  }

  public deletePanelWeightMatrixItem(id: number) {
    return fetch(this._apiBasePath + "/panels/" + id, {
      method: "delete",
    });
  }
}