import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../scss/Layout.scss";
import JtpNavbar from "./JtpNavbar";
import { config } from "../Config";
import ErrorMessage from "../components/ErrorMessage";

export default function Layout(props: any) {  
  
  let error = null;
  if (props.error) {
    error = (
      <ErrorMessage message={props.error.message} debug={props.error.debug} />
    );
  }
  return (
    <div>
      <header>
        
          <JtpNavbar isAuthenticated={props.isAuthenticated}
                  authButtonMethod={
                    props.isAuthenticated ? props.logout : props.login
                  }
                  user={props.user}
                  getAccessToken={() => props.getAccessToken(config.scopes)} />
          {/* <div className="brandingSection">
          <Container fluid>
            <Row className="align-items-end">
              <Col xs="9" className="col-md text-left">
                <Link to="/" className="navbar-brand h1">
                  Tri-County Metals Quoting
                </Link>
              </Col>
              <Col xs="3" md="4" className="text-right">
                <FbAuthNavItem
                  isAuthenticated={props.isAuthenticated}
                  authButtonMethod={
                    props.isAuthenticated ? props.logout : props.login
                  }
                  user={props.user}
                  getAccessToken={() => props.getAccessToken(config.scopes)}
                />
              </Col>
            </Row>
          </Container>
          </div> */}
        
        
      </header>      
      <div className="layoutWrapper">
        <Container fluid={true}>
          {error}
          <main role="main">{props.children}</main>
        </Container>
      </div>
      <footer className="border-top footer text-muted">
        <Container>
          <Row>
            <Col xs="6">
              &copy; {(new Date()).getFullYear()} - Stalwart Enterprises, LLC              
            </Col>
            <Col xs="6" className="text-right">
              &nbsp;
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}