import React, { ChangeEvent } from "react";
import { Table, Input, Button } from "reactstrap"
import TcmQuotingService from "../../services/TcmQuotingService";
import { config } from "../../Config";
import { PanelProfile } from "../../types";

export interface IPanelProfilesState {
  panelsProfileName?: string,
  panelsPanelWidth?: number,
  panelsBoardSpacing?: number,
}

export interface IPanelProfilesProps {
  data: PanelProfile[],
  refreshData: Function
}

export class PanelProfilesTable extends React.Component <IPanelProfilesProps, IPanelProfilesState> {
  private tcmSvc: TcmQuotingService;
  constructor(props: any) {
    super(props);

    this.state = {
      panelsProfileName: "",
      panelsPanelWidth: 0,
      panelsBoardSpacing: 0,      
    };

    this.tcmSvc = new TcmQuotingService(config.apiBaseUrl);
    
    this.onFieldChange = this.onFieldChange.bind(this);
    this.addRecord = this.addRecord.bind(this);
    
  }

  onFieldChange(event: ChangeEvent<HTMLInputElement>) {
    // update the field.
    this.setState({ [event.currentTarget.name]: event.currentTarget.value }); 
  }


  deleteRecordById(id: number) {
    if (window.confirm("Are you sure you want to delete this?")) { 
      this.tcmSvc.deletePanelProfile(id)
        .then(() => this.props.refreshData());
    }
  }

  addRecord() {
    if (this.state.panelsBoardSpacing === undefined
      || (this.state.panelsPanelWidth === undefined || this.state.panelsPanelWidth <= 0) 
      || (this.state.panelsProfileName === undefined || this.state.panelsProfileName === "")) {
      alert("please give your new Panel Profile a Name and width.");
    } else {
      this.tcmSvc.createPanelProfile({
        id: 0,
        panelWidth: this.state.panelsPanelWidth,
        profileName: this.state.panelsProfileName,
        boardSpacing: this.state.panelsBoardSpacing
      }).then(() => {
        this.setState({ panelsBoardSpacing: 0, panelsPanelWidth: 0, panelsProfileName: "" });
        this.props.refreshData();
      });
    }
  }

  componentDidMount() {
    //this.props.refreshData();
  }

  getPanelProfilesTableRows(): any {
    return this.props.data.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.profileName}</td>
          <td>{item.panelWidth}</td>
          <td>{item.boardSpacing}</td>
          <td>
            <Button onClick={() => this.deleteRecordById(item.id)}
              color="danger" size="sm">
              Delete
            </Button>
          </td>
        </tr>
      );
    });
  }

  render() { 
    console.log("PanelProfilesTable- Render");
    console.log(this.props.data);
    return (
      <Table striped={true} size="sm">
        <thead>
          <tr>
            <th scope="col">Profile Name!</th>
            <th scope="col">Panel Width</th>
            <th scope="col">Board Spacing</th>
            <th scope="col">&nbsp;</th>
          </tr>
          <tr>
            <th>
              <Input type="text"
                name="panelsProfileName"
                id="panelsProfileName" 
                placeholder="New Profile Name"
                value={this.state.panelsProfileName}
                onChange={this.onFieldChange}
              />
            </th>
            <th>
              <Input type="number" step={.25}
                name="panelsPanelWidth"
                id="panelsPanelWidth" 
                placeholder="Panel Width (inches)"
                value={this.state.panelsPanelWidth}
                onChange={this.onFieldChange}
              />
            </th>
            <th>
              <Input type="number"
                name="panelsBoardSpacing"
                id="panelsBoardSpacing"
                placeholder="Board Spacing (inches)"
                value={this.state.panelsBoardSpacing}
                onChange={this.onFieldChange}
              />
            </th>
            <th>
              <Button color="success" onClick={this.addRecord}>Add</Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.getPanelProfilesTableRows()}
        </tbody>
      </Table>
    )
  }

}