import React from "react";
import {
  Nav,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
} from "reactstrap";
import JtpAuthNavItem from "./JtpAuthNavItem";

interface JtpNavbarState {
  isOpen: boolean;
}

export default class JtpNavbar extends React.Component<any, JtpNavbarState> {
  constructor(props: any) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    console.log(this.state.isOpen);
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <Navbar 
        className="navbar nav-justified navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow bg-primary"
        dark
      >
        <NavbarBrand href="/" className="pl-3">
          <img src="logo192.png" alt="Tri-County Metals" />
          Tri-County Metals Quoting</NavbarBrand>
        <NavbarToggler className="mr-2" />
        <Collapse
          className="d-sm-inline-flex flex-sm-row-reverse"
          isOpen={true}
          navbar
        >
          <Nav className="ml-auto">
            <JtpAuthNavItem {...this.props} />
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
