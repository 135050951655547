import React from "react";
import { Alert, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { PanelWeightMatrixTable } from "./manageTables/PanelWeightMatrixTable";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { PanelProfilesTable } from "./manageTables/PanelProfilesTable";
import { WeightProfilesTable } from "./manageTables/WeightProfilesTable";
import { PanelDetail, PanelProfile, WeightProfile } from "../types";
import TcmQuotingService from "../services/TcmQuotingService";
import { config } from "../Config";
import { timeStamp } from "console";

export interface IManageTablesPageState {
  activeTab: number  
  panelWeightMatrixProfiles: PanelDetail[],
  panelProfiles: PanelProfile[],
  weightProfiles: WeightProfile[],  
}


export class ManageTablesPage extends React.Component<any, IManageTablesPageState> {
  private tcmSvc: TcmQuotingService;
  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: 1,
      panelWeightMatrixProfiles: [],
      panelProfiles: [],
      weightProfiles: [],
    }

    this.tcmSvc = new TcmQuotingService(config.apiBaseUrl);

    this.toggle = this.toggle.bind(this);
    this.getPanelProfileData = this.getPanelProfileData.bind(this);
    this.getPanelWeightMatrixData = this.getPanelWeightMatrixData.bind(this);
    this.getWeightProfileData = this.getWeightProfileData.bind(this);
  }

  toggle(tab: number) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
      switch (tab) {
        case 1:
          
          this.getPanelProfileData();
          this.getWeightProfileData();
          this.getPanelWeightMatrixData();
          
          break;
        case 2:
          this.getPanelProfileData();
          break;
        case 3:
          this.getWeightProfileData();
          break;        

      }
    }
  }

  async getPanelWeightMatrixData() {
    console.log("getPanelWeightMatrixData");
    const data = await this.tcmSvc.getPanelWeightMatrixDetails();
    if (data) {
      this.setState({ panelWeightMatrixProfiles: data });
    }
  }

  async getWeightProfileData() {
    console.log("getWeightProfileData");
    const data = await this.tcmSvc.getWeightProfiles();
    if (data) {
      this.setState({ weightProfiles: data });
    }
  }

  async getPanelProfileData() {
    console.log("getPanelProfileData");
    const data = await this.tcmSvc.getPanelProfiles();
    if (data) {
      this.setState({ panelProfiles: data });
    }
  }

  componentDidMount() {
    this.getPanelProfileData();
    this.getWeightProfileData();
    this.getPanelWeightMatrixData();
  }

  render() {
    return (
      <div>
        <h1>Manage Tables!</h1>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 1 })}
              onClick={() => { this.toggle(1); }}>
              Panel Weight Matrix
            </NavLink>            
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 2 })}
              onClick={() => { this.toggle(2); }}>
              Panel Profiles
            </NavLink>            
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === 3 })}
              onClick={() => { this.toggle(3); }}>
              Weight Profiles
            </NavLink>            
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={1}>
            <Alert color="info">
              
                <FontAwesomeIcon icon={faInfoCircle} /> &nbsp;
                This table intersects panel profile, guage/weight and screw spacing.  This matrix drives the drop downs on the quote screen.
              
            </Alert>
            <PanelWeightMatrixTable
              panelWeightMatrixProfiles={this.state.panelWeightMatrixProfiles}
              panelProfiles={this.state.panelProfiles}
              weightProfiles={this.state.weightProfiles}
              refreshData={this.getPanelWeightMatrixData} />
          </TabPane>
          <TabPane tabId={2}>
            <PanelProfilesTable
              data={this.state.panelProfiles}
              refreshData={this.getPanelProfileData} />
          </TabPane>
          <TabPane tabId={3}>
            <WeightProfilesTable
              data={this.state.weightProfiles}
              refreshData={this.getWeightProfileData} />
          </TabPane>
        </TabContent>
        
      </div>
    );
  }  
}
