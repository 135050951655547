import React, { ChangeEvent } from "react";
import { Table, Input, Button } from "reactstrap"
import TcmQuotingService from "../../services/TcmQuotingService";
import { config } from "../../Config";
import { PanelDetail, PanelProfile, WeightProfile } from "../../types";

export interface IWeightProfilesState {
  weightsGaugeName?: string,
  weightsWeightBySqFt?: number
}

export interface IWeightProfilesProps {
  data: WeightProfile[],
  refreshData: Function
}

export class WeightProfilesTable extends React.Component<IWeightProfilesProps, IWeightProfilesState> {
  private tcmSvc: TcmQuotingService;
  constructor(props: IWeightProfilesProps) {
    super(props);

    this.state = {
      weightsGaugeName: "",
      weightsWeightBySqFt: 0
    };

    this.tcmSvc = new TcmQuotingService(config.apiBaseUrl);
    
    this.onFieldChange = this.onFieldChange.bind(this);
    this.addRecord = this.addRecord.bind(this);
  }
  
  onFieldChange(event: ChangeEvent<HTMLInputElement>) {
    // update the field.
    console.log(event);
    this.setState({ [event.currentTarget.name]: event.currentTarget.value }); 
  }

  deleteRecordById(id: number) {
    if (window.confirm("Are you sure you want to delete this?")) { 
      this.tcmSvc.deleteWeightProfile(id)
        .then(() => this.props.refreshData());
    }
  }

  addRecord() {
    if ((this.state.weightsGaugeName === undefined || this.state.weightsGaugeName === "")
      || (this.state.weightsWeightBySqFt === undefined || this.state.weightsWeightBySqFt === 0)) {
      alert("Please give your new Weight Profile a name and width.");
    } else {
      this.tcmSvc.createWeightProfile({
        id: 0,
        gaugeName: this.state.weightsGaugeName,
        weightBySqFt: +this.state.weightsWeightBySqFt
      }).then(() => {
        this.setState({ weightsGaugeName: "", weightsWeightBySqFt: 0 });
        this.props.refreshData();
      }); 
    }
  }

  getWeightProfilesTableRows(): any {
    return this.props.data.map((item) => {
      return <tr key={item.id}>
        <td>{item.gaugeName}</td>
          <td>{item.weightBySqFt}</td>
          <td>
            <Button onClick={() => this.deleteRecordById(item.id)}
              color="danger" size="sm">
              Delete
            </Button>
          </td>
      </tr>
    })
  }  
  
  componentDidMount() {
    
  }

  render() {
    return (<Table striped={true} size="sm">
        <thead>
          <tr>
            <th scope="col">Gauge Name</th>
            <th scope="col">Weight by Sq. Ft.</th>
            <th scope="col">&nbsp;</th>
          </tr>
          <tr>
            <th>
            <Input type="text"
              name="weightsGaugeName"
              id="weightsGaugeName"
              placeholder="New Gauge Name"
              value={this.state.weightsGaugeName}
              onChange={this.onFieldChange}
            />
            </th>
            <th>
            <Input type="number"
              name="weightsWeightBySqFt"
              id="weightsWeightBySqFt"
              placeholder="Weight by Sq. Ft."
              step={.1}
              value={this.state.weightsWeightBySqFt}
              onChange={this.onFieldChange}
            />
            </th>            
            <th>
              <Button color="success" onClick={this.addRecord}>Add</Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.getWeightProfilesTableRows()}
        </tbody>
      </Table>)
  }
}

