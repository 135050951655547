import React, { ChangeEvent, FormEvent } from "react";
import { Input, Form, Row, Col, FormGroup, Label, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { PanelOrderLine } from "../types";

export interface IPanelsFormState {
  numPanels?: number;
  numFeet?: number;
  numInches?: number;
  addPanelsError?: string;
}

export class PanelsForm extends React.Component<any, IPanelsFormState> {
  constructor(props: any) {
    super(props);
    this.state = {
      numFeet: 0,
      numInches: 0,
      numPanels: 0,
      addPanelsError: "",
    } as IPanelsFormState;

    this.onAddPanelsClick = this.onAddPanelsClick.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  onFieldChange(event: ChangeEvent<HTMLInputElement>) {
    // update the field.
    this.setState({ [event.currentTarget.name]: event.currentTarget.value }); 
  }

  onAddPanelsClick(event: FormEvent) {
    event.preventDefault();    

    if (this.validateForm() === false) {      
      return;
    }
        
    this.props.onAddPanelsClick(this.state);
    this.setState({ numFeet: 0, numInches: 0, numPanels: 0 });    
    this.setFirstFocus();
  }

  validateForm() : boolean {
    let isValid: boolean = true;
    let addPanelsError: string = "";

    if (this.state.numPanels == null || this.state.numPanels < 1) {
      addPanelsError = "# Panels must be more than 0. ";
      isValid = false;
    }

    const newInches = (this.state.numInches as number) % 12;
    const newFeet =
      +(this.state.numFeet as number) +
      (+(this.state.numInches as number) - newInches) / 12;
    const totalInches = newFeet * 12 + newInches;
    console.log("totalInches: " + totalInches);

    if (totalInches < 36) {
      addPanelsError += "Minimum panel length is 3ft. (36in.)";
      isValid = false;
    }
    this.setState({ addPanelsError: addPanelsError });
    return isValid;
  }

  setFirstFocus() {
    const focusOn = document.getElementById("numPanels") as HTMLInputElement;
    focusOn?.focus();
    focusOn?.select();
  }
  componentDidMount() {
    // this.setFirstFocus();
  }

  render() {
    const formLabelClass = "";
    return (
      <Form>
        <Row>
          <Col className="text-center">
            <h5>Enter Panel Information</h5>
          </Col>
        </Row>
        <Row>
        <Col className="text-right"><span>Minimum Panel Length 3ft. (36in.)</span></Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="numPanels" className={formLabelClass}>
                &#35; Panels
              </Label>
              <Input
                type="number"
                id="numPanels"
                name="numPanels"
                min={0}
                value={this.state.numPanels}
                onChange={this.onFieldChange}
                tabIndex={1}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="numFeet" className={formLabelClass}>
                Feet
              </Label>
              <Input
                type="number"
                id="numFeet"
                name="numFeet"
                min={0}
                value={this.state.numFeet}
                onChange={this.onFieldChange}
                tabIndex={2}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="numInches" className={formLabelClass}>
                Inches
              </Label>
              <Input
                type="number"
                id="numInches"
                name="numInches"
                min={0}
                value={this.state.numInches}
                onChange={this.onFieldChange}
                tabIndex={3}
              />
            </FormGroup>
          </Col>
          <Col xs="2">
            <FormGroup>
              <Label for="addPanelsButton">&nbsp;</Label>
              <br />
              <Button
                name="addPanelsButton"
                id="addPanelsButton"
                type="button"
                onClick={this.onAddPanelsClick}
                tabIndex={4}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="text-warning"><b>{this.state.addPanelsError}</b></span>
          </Col>          
        </Row>
      </Form>
    );
  }
}

export class AddPanels extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.onDeletePanelClick = this.onDeletePanelClick.bind(this);
    this.onCopyClick = this.onCopyClick.bind(this);
  }

  onDeletePanelClick(key: number) {
    this.props.onPanelLineDelete(key);
  }

  async onCopyClick() {  
    var entriesText = (this.props.panelEntries
      .sort((a: any, b: any) => {
        const leftNum: number = this.getTotalInches(a);
        const rightNum: number = this.getTotalInches(b);
        // Sorts descending
        if (leftNum < rightNum) return 1;
        if (leftNum > rightNum) return -1;

        return 0;
      })
      .map((entry: PanelOrderLine) => {
        const totalInches = this.getTotalInches(entry);
        const newInches = totalInches % 12;
        const newFeet = (totalInches - newInches) / 12;

        return (
          entry.numPanels.toString().padEnd(10) + " - " +
          (newFeet.toString() + "' " + newInches.toString() + '" ').padEnd(10) +
          ("= " + entry.lengthInInches.toString()).padEnd(10)
        );
      }) as Array<string>).join(" \r\n");
    
    if (this.props.postMessageOnCopy) {
      let descriptionHeader = [
        "Qty.".padEnd(10) + "Ft. & In.".padEnd(10) + "Inches".padEnd(10),
        " \r\n",
      ].join("\r\n");

      let obj = {
        pLinearFeetElem: this.props.calculatedValues.linearFeet.toLocaleString("en-us", {
          minimumFractionDigits: 2
        }),
        pDescriptionElem: descriptionHeader + entriesText
      };
      
      window.parent.postMessage(obj, "*");
    }
    else {
      let entriesHeader: string = [
        "Weight: " +
        this.props.calculatedValues.totalWeight.toLocaleString("en-us", 1) +
        "lbs. \r\nLinear Ft.: " + this.props.calculatedValues.linearFeet.toLocaleString("en-us", {
          minimumFractionDigits: 2
        }),
        "Qty.".padEnd(10) + "Ft. & In.".padEnd(10) + "Inches".padEnd(10),
        " \r\n",
      ].join("\r\n");

      await navigator.clipboard.writeText(entriesHeader + entriesText);
    }
  }

  render() {
    let weightClassName = this.props.hidePanelWeight ? "d-none" : "";
    return (
      <Col xs="12" sm="12" md="4" lg="3" className="panel-info-section">
        <PanelsForm {...this.props} />

        <Row>
          <Col>
            <span className={weightClassName}>
              Weight:{" "}
              {this.props.calculatedValues.totalWeight.toLocaleString("en-us", {
                maximumFractionDigits: 2,
              })}{" "}lbs. <br />
            </span>
            Linear Feet: {this.props.calculatedValues.linearFeet.toLocaleString("en-us", {
              maximumFractionDigits: 2 
            })}
          </Col>
          <Col xs={3}>
            <Button
              color="light"
              className="btn-sm"
              onClick={this.onCopyClick}
            >
              Calculate
              {/* <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-clipboard-plus"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                />
                <path
                  fillRule="evenodd"
                  d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3zM8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"
                />
              </svg> */}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="">
            <b>Qty.</b>
          </Col>
          <Col className="">
            <b>Ft. &amp; In.</b>
          </Col>
          <Col className="">
            <b>Inches</b>
          </Col>
          <Col xs={2} className="">
            &nbsp;
          </Col>
        </Row>
        {this.props.panelEntries
          .sort((a: any, b: any) => {
            const leftNum: number = this.getTotalInches(a);
            const rightNum: number = this.getTotalInches(b);
            // Sorts descending
            if (leftNum < rightNum) return 1;
            if (leftNum > rightNum) return -1;

            return 0;
          })
          .map((entry: PanelOrderLine) => {
            return (
              <Row key={entry.lengthInInches}>
                <Col className="">{entry.numPanels}</Col>
                <Col className="">
                  - {entry.numFeet} &#8242; {entry.numInches} &#8243;
                </Col>
                <Col className="">= {entry.lengthInInches}</Col>
                <Col xs={2}>
                  <Button
                    color="link"
                    className="btn btn-sm btn-link text-danger"
                    onClick={() =>
                      this.onDeletePanelClick(entry.lengthInInches)
                    }
                  >
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      className="bi bi-trash-fill"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
                      />
                    </svg>
                  </Button>
                </Col>
              </Row>
            );
          })}
      </Col>
    );
  }

  getTotalInches(entry: IPanelsFormState): number {
    const numFeet: number =
      entry.numFeet != null ? (entry.numFeet as number) : 0;
    const numInches: number =
      entry.numInches != null ? (entry.numInches as number) : 0;
    const total: number = +numInches + this.feetInInches(+numFeet);
    return total;
  }

  feetInInches(feet: number): number {
    return feet * 12;
  }
}

export default AddPanels;
