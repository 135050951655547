import React, {createContext} from "react";
import { getUserDetails, getUserPhoto } from "./services/GraphService";
import { UserAgentApplication, Configuration } from "msal";

import { config } from "./Config";
import { faSmileBeam } from "@fortawesome/free-regular-svg-icons";

export interface AuthComponentProps {
  error: any;
  isAuthenticated: boolean;
  user: any;
  login: Function;
  logout: Function;
  getAccessToken: Function;
  setError: Function;
}

interface AuthProviderState {
  error: any;
  isAuthenticated: boolean;
  user: any;
  account: any;
}

export const AuthContext = createContext({ 
  error: {},
  isAuthenticated: false,
  user: {},
  account: {}
});

export default function withAuthProvider<
  T extends React.Component<AuthComponentProps>
>(
  WrappedComponent: new (props: AuthComponentProps, context?: any) => T
): React.ComponentClass {
  return class extends React.Component<any, AuthProviderState> {
    private userAgentApplication: UserAgentApplication;

    constructor(props: any) {
      super(props);
      this.state = {
        error: null,
        isAuthenticated: false,
        user: {},
        account: []
      };

      
      // Initialize the MSAL application object
      this.userAgentApplication = new UserAgentApplication({
        auth: {
          authority: config.authority + config.domain,
          clientId: config.appId,
          redirectUri: config.redirectUri,
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: false,
        }
        
      });
    }  

    componentDidMount() {      
      console.log("authProvider-componentDidMount");
      // If MSAL already has an account, the user
      // is already logged in
      const account = this.userAgentApplication.getAccount();
      this.setState({
        account
      });

      if (!account && this.userAgentApplication.urlContainsHash(window.location.hash) && !this.userAgentApplication.getLoginInProgress()) {
        //window.location.href = process.env.PUBLIC_URL;
      }

      if (account) {
        this.getUserProfile();
      }      
    }

    render() {

      this.userAgentApplication.handleRedirectCallback(() => {
        console.log("handleRedirectCallback");
        let userAccount = this.userAgentApplication.getAccount();
        console.log(userAccount);
        this.setState({
          isAuthenticated: true,
          user: userAccount
        });
      }, (err, accountState) => {
        console.log(err);
        this.setState({
          error: this.normalizeError(err)
        });
      });

      console.log(this.userAgentApplication.getAccount());
      console.log(this.userAgentApplication.urlContainsHash);

      return (
        <AuthContext.Provider value={this.state}>
        <WrappedComponent
          login={() => this.login()}
          logout={() => this.logout()}
          getAccessToken={(scopes: string[]) => this.getAccessToken(scopes)}
          setError={(message: string, debug: string) =>
            this.setErrorMessage(message, debug)
          }
          {...this.props}
          {...this.state}
          />
          </AuthContext.Provider>
      );
    }

    async login() {
      try {
        // Login via redirect.
        this.userAgentApplication.loginRedirect({ scopes: config.scopes });
        // After login, get the user's profile
      } catch (err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err),
        });
      }
    }

    logout() {
      this.userAgentApplication.logout();
    }

    async getAccessToken(scopes: string[]): Promise<string>{
      try {
        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        const account = this.userAgentApplication.getAccount();
        var silentResult = await this.userAgentApplication.acquireTokenSilent({
          scopes: scopes
        });
        console.log(silentResult);
        return silentResult.accessToken;
      } catch (err) {
        console.log(err);
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        // if (err.errorCode === "block_token_requests") {                  
        if (this.isInteractionRequired(err)) {
          await this.userAgentApplication.acquireTokenRedirect(
            {
              scopes: scopes,
            }
          ); 
          return "";
        } else {
          throw err;
        }
      }
    }

    async getUserProfile() {
      try {
        const accessToken = await this.getAccessToken(config.scopes);
        if (accessToken) {
          let photo: any;
          // Get the user's profile from Graph          
          const user = await getUserDetails(accessToken);          
          try {
            photo = await getUserPhoto(accessToken);
          } catch (err) {
            photo = undefined;
          }
          this.setState({
            isAuthenticated: true,
            user: {
              id: user.id,
              givenName: user.givenName,
              surname: user.surname,
              displayName: user.displayName,
              jobTitle: user.jobTitle,
              email: user.mail || user.userPrincipalName,
              avatar: photo
            },
            error: null,
          });
        }
      } catch (err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          account: {},
          error: this.normalizeError(err),
        });
      }
    }

    setErrorMessage(message: string, debug: string) {
      this.setState({
        error: { message: message, debug: debug },
      });
    }

    normalizeError(error: string | Error): any {
      var normalizedError = {};
      if (typeof error === "string") {
        var errParts = error.split("|");
        normalizedError =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: error };
      } else {
        normalizedError = {
          message: error.message,
          debug: JSON.stringify(error),
        };
      }
      return normalizedError;
    }

    isInteractionRequired(error: Error): boolean {
      if (!error.message || error.message.length <= 0) {
        return false;
      }

      return (
        error.message.indexOf("consent_required") > -1 ||
        error.message.indexOf("interaction_required") > -1 ||
        error.message.indexOf("login_required") > -1 ||
        error.message.indexOf("null_or_empty_id_token") > -1
      );
    }
  };
}


