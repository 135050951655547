import React from "react";
import { Row, Col, Input } from "reactstrap";

export interface IQuoteDetailsState {
  ridge: number;
  hip: number;
  endwall: number;
  transition: number;
  gable: number;
  sidewall: number;
  valley: number;
  drip: number;
}

export class QuoteDetails extends React.Component<any, IQuoteDetailsState> {
  constructor(props: any, state: IQuoteDetailsState) {
    super(props);
    this.state = {
      ridge: props.ridge,
      hip: props.hip,
      endwall: props.endwall,
      transition: props.transition,
      gable: props.gable,
      sidewall: props.sidewall,
      valley: props.valley,
      drip: props.drip,
    };

    this.onFieldChange = this.onFieldChange.bind(this);
  }

  onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      ...this.state,
      [event.currentTarget.name]: Number(event.currentTarget.value),
    });
    
    // So, here's what I'm doing here.  Since the state is going to be reinitialized by the props being passed in,
    // we're gonna have to send a copy of the state object with the change we made because of when the change is reflected
    // here.  This is really a job for redux I think, because the state being copied is subjected to a lifecycle that's
    // not obvious here. This led to calculated values lagging behind because the work was being done by another version
    // of state in the parent. 
    this.props.roofCharacteristicsChanged({
      ridge: event.currentTarget.name === 'ridge' ? Number(event.currentTarget.value) : this.state.ridge,
      hip: event.currentTarget.name === 'hip' ? Number(event.currentTarget.value) : this.state.hip,
      endwall: event.currentTarget.name === 'endwall' ? Number(event.currentTarget.value) : this.state.endwall,
      transition: event.currentTarget.name === 'transition' ? Number(event.currentTarget.value) : this.state.transition,
      gable: event.currentTarget.name === 'gable' ? Number(event.currentTarget.value) : this.state.gable,
      sidewall: event.currentTarget.name === 'sidewall' ? Number(event.currentTarget.value) : this.state.sidewall,
      valley: event.currentTarget.name === 'valley' ? Number(event.currentTarget.value) : this.state.valley,
      drip: event.currentTarget.name === 'drip' ? Number(event.currentTarget.value) : this.state.drip
    } as IQuoteDetailsState);
  }

  render() {
    const metricRowClassName = "small mb-1 striped-cols";
    const metricColClassName = "mr-1 ml-1 border border-secondary";
    const metricColHeadClassName = "p-0 h-50 font-weight-bold";
    const metricColBodyClassName = "p-0 h-50 mt-1";

    const metricRowAltClassName = "small mb-1";
    const metricColAltClassName = "mr-1 ml-1 p-0 border border-secondary";
    const metricColAltHeadClassName =
      "p-2 h-50 bg-primary text-light text-uppercase font-weight-bold";
    const metricColAltBodyClassName = "p-1 h-50 mt-1 mb-1";
    const stealthBondColClassName =
      "mr-1 ml-1 border border-info bg-info";

    return (
      <React.Fragment>
        <Row className={metricRowClassName}>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Linear Feet
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.linearFeet.toLocaleString("en-us", {
                minimumFractionDigits: 2,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Square Feet
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.squareFeet.toLocaleString("en-us", {
                minimumFractionDigits: 2,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Purlins
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.purlins.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Panel Screws
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.panelScrews.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Trim Screws
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.trimScrews.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Lap Screws
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.lapScrews.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              6" Peel &amp; Seal
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.sixInchPeelSeal.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
        </Row>
        <Row className={metricRowClassName}>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Double Bubble
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.doubleBubble.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Synthetic Underlayment
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.syntheticUnderlayment.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Purlin Screws
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.purlinScrews.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Purlin Nails
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.purlinNails.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Tacky Tape Panel Laps
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.tackyTapePanelLaps.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Tacky Tape Trim
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.tackyTapeTrim.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Expanding Foam
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.expandingFoam.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
        </Row>
        <Row className={metricRowClassName}>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Outside Closures
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.outsideClosures.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Inside Closures
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.insideClosures.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Flex-O-Vent / FLEX PRO
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.flex_o_vent.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName + " invisible"}>
            <Col xs="12" className={metricColHeadClassName}>
              &nbsp;
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              &nbsp;
            </Col>
          </Col>
          <Col className={metricColClassName + " invisible"}>
            <Col xs="12" className={metricColHeadClassName}>
              &nbsp;
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              &nbsp;
            </Col>
          </Col>
          <Col className={metricColClassName + " invisible"}>
            <Col xs="12" className={metricColHeadClassName}>
              &nbsp;
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              &nbsp;
            </Col>
          </Col>
          <Col className={metricColClassName + " invisible"}>
            <Col xs="12" className={metricColHeadClassName}>
              &nbsp;
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              &nbsp;
            </Col>
          </Col>
        </Row>

        <Row className={metricRowAltClassName}>
          <Col className={metricColAltClassName}>
            <Col xs="12" className={metricColAltHeadClassName}>
              Ridge
            </Col>
            <Col xs="12" className={metricColAltBodyClassName}>
              <Input
                type="number"
                min={0}
                name="ridge"
                id="ridgeEntry"
                className="form-control-sm"
                value={this.state.ridge}
                onChange={this.onFieldChange}
              />
            </Col>
          </Col>
          <Col className={metricColAltClassName}>
            <Col xs="12" className={metricColAltHeadClassName}>
              Hip
            </Col>
            <Col xs="12" className={metricColAltBodyClassName}>
              <Input
                type="number"
                min={0}
                name="hip"
                id="hipEntry"
                className="form-control-sm"
                value={this.state.hip}
                onChange={this.onFieldChange}
              />
            </Col>
          </Col>
          <Col className={metricColAltClassName}>
            <Col xs="12" className={metricColAltHeadClassName}>
              Endwall
            </Col>
            <Col xs="12" className={metricColAltBodyClassName}>
              <Input
                type="number"
                min={0}
                name="endwall"
                id="endwallEntry"
                className="form-control-sm"
                value={this.state.endwall}
                onChange={this.onFieldChange}
              />
            </Col>
          </Col>
          <Col className={metricColAltClassName}>
            <Col xs="12" className={metricColAltHeadClassName}>
              Transition
            </Col>
            <Col xs="12" className={metricColAltBodyClassName}>
              <Input
                type="number"
                min={0}
                name="transition"
                id="transitionEntry"
                className="form-control-sm"
                value={this.state.transition}
                onChange={this.onFieldChange}
              />
            </Col>
          </Col>
          <Col className={metricColAltClassName}>
            <Col xs="12" className={metricColAltHeadClassName}>
              Gable
            </Col>
            <Col xs="12" className={metricColAltBodyClassName}>
              <Input
                type="number"
                min={0}
                name="gable"
                id="gableEntry"
                className="form-control-sm"
                value={this.state.gable}
                onChange={this.onFieldChange}
              />
            </Col>
          </Col>
          <Col className={metricColAltClassName}>
            <Col xs="12" className={metricColAltHeadClassName}>
              Sidewall
            </Col>
            <Col xs="12" className={metricColAltBodyClassName}>
              <Input
                type="number"
                min={0}
                name="sidewall"
                id="sidewallEntry"
                className="form-control-sm"
                value={this.state.sidewall}
                onChange={this.onFieldChange}
              />
            </Col>
          </Col>
          <Col className={metricColAltClassName}>
            <Col xs="12" className={metricColAltHeadClassName}>
              Valley
            </Col>
            <Col xs="12" className={metricColAltBodyClassName}>
              <Input
                type="number"
                min={0}
                name="valley"
                id="valleyEntry"
                className="form-control-sm"
                value={this.state.valley}
                onChange={this.onFieldChange}
              />
            </Col>
          </Col>
          <Col className={metricColAltClassName}>
            <Col xs="12" className={metricColAltHeadClassName}>
              Drip
            </Col>
            <Col xs="12" className={metricColAltBodyClassName}>
              <Input
                type="number"
                min={0}
                name="drip"
                id="dripEntry"
                className="form-control-sm"
                value={this.state.drip}
                onChange={this.onFieldChange}
              />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col className="text-center bg-info">Standing Seam</Col>
        </Row>
        <Row className={metricRowClassName}>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Z-Flashing
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.zFlashing.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Panel Starters
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.panelStarters.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Eave Cleats
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.eaveCleats.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Pancake Screws
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.pancakeScrews.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Rivets
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.rivets.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Clips
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.clips.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Titebond
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.titebond.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
        </Row>
        <Row className={metricRowClassName}>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Tack Tape
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.tackTape.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={metricColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Peel &amp; Stick Underlayment
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.peelStickUnderlayment.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          
          {/* <Col className={stealthBondColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              <span className="h5">
                Stealthbond
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-caret-right-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
              </span>
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              <span>&nbsp;</span>
            </Col>
          </Col> */}
          <Col className={stealthBondColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Z-Flashing
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.stealthBondZFlashing.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          <Col className={stealthBondColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Rivets
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.rivets.toLocaleString("en-us", {
                minimumFractionDigits: 0,
              })}
            </Col>
          </Col>
          <Col className={stealthBondColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              Pancakes
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.stealthBondPancakes.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col>
          <Col className={metricColClassName + " invisible"}>
            <Col xs="12" className={metricColHeadClassName}>
              &nbsp;
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              &nbsp;
            </Col>
          </Col>
          <Col className={metricColClassName + " invisible"}>
            <Col xs="12" className={metricColHeadClassName}>
              &nbsp;
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              &nbsp;
            </Col>
          </Col>
          {/* <Col className={stealthBondColClassName}>
            <Col xs="12" className={metricColHeadClassName}>
              SB Ringshank
            </Col>
            <Col xs="12" className={metricColBodyClassName}>
              {this.props.calculatedValues.stealthBondRingshank.toLocaleString(
                "en-us",
                {
                  minimumFractionDigits: 0,
                }
              )}
            </Col>
          </Col> */}
        </Row>
      </React.Fragment>
    );
  }
}

export default QuoteDetails;
