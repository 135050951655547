import React, { ChangeEvent } from "react";
import { Input, Row, Col, Container } from "reactstrap";

import "../scss/QuoteWorksheet.scss";
import AddPanelsForm, { IPanelsFormState } from "../components/AddPanelsForm";
import QuoteDetails, { IQuoteDetailsState } from "../components/QuoteDetails";
import { CalculatedValues, PanelDetail, PanelOrderLine } from "../types";
import Calculate from "../quoteCalculations";
import { config } from "../Config";

export interface IQuoteWorksheetState {
  panelEntries: PanelOrderLine[];
  panelDetails: PanelDetail[];
  selectedDetailId: number;
  selectedPanelProfileId: number;
  selectedWeightProfileId: number;
  ridge: number;
  hip: number;
  endwall: number;
  transition: number;
  gable: number;
  sidewall: number;
  valley: number;
  drip: number;
  calculatedValues: CalculatedValues;
}

export class QuoteWorksheetPage extends React.Component<any, IQuoteWorksheetState> {
  constructor(props: any) {
    super(props);
    this.state = {
      panelEntries: [],
      panelDetails: [],
      selectedDetailId: 0,
      selectedPanelProfileId: 0,
      selectedWeightProfileId: 0,
      ridge: 0,
      hip: 0,
      endwall: 0,
      transition: 0,
      gable: 0,
      sidewall: 0,
      valley: 0,
      drip: 0,
      calculatedValues: {
        totalWeight: 0,
        screws: 0,
        linearFeet: 0,
        linearInches: 0,
        squareFeet: 0,
        purlins: 0,
        panelScrews: 0,
        trimScrews: 0,
        lapScrews: 0,
        sixInchPeelSeal: 0,
        doubleBubble: 0,
        syntheticUnderlayment: 0,
        purlinScrews: 0,
        purlinNails: 0,
        tackyTapePanelLaps: 0,
        tackyTapeTrim: 0,
        expandingFoam: 0,
        outsideClosures: 0,
        insideClosures: 0,
        flex_o_vent: 0,
        zFlashing: 0,
        panelStarters: 0,
        eaveCleats: 0,
        pancakeScrews: 0,
        rivets: 0,
        clips: 0,
        titebond: 0,
        tackTape: 0,
        peelStickUnderlayment: 0,
        stealthBondZFlashing: 0,
        stealthBondRivets: 0,
        stealthBondPancakes: 0,
        stealthBondRingshank: 0,
        boards: 0,
      },
    };

    this.onAddPanelEntryClicked = this.onAddPanelEntryClicked.bind(this);
    this.onPanelEntryDeleted = this.onPanelEntryDeleted.bind(this);
    this.onPanelProfileChanged = this.onPanelProfileChanged.bind(this);
    this.onWeightProfileChanged = this.onWeightProfileChanged.bind(this);
    this.getPanelDetails = this.getPanelDetails.bind(this);
    this.onRoofCharacteristicsChanged = this.onRoofCharacteristicsChanged.bind(
      this
    );
    this.updateCalculatedValues = this.updateCalculatedValues.bind(this);
  }

  onRoofCharacteristicsChanged(vals: IQuoteDetailsState) {
    const panelDetail = this.state.panelDetails.find((c: PanelDetail) => {
      return c.id === this.state.selectedDetailId;
    });

    if (panelDetail) {
      console.log("update calulated values");
      this.setState({
        ...this.state,
        ridge: vals.ridge,
        hip: vals.hip,
        endwall: vals.endwall,
        transition: vals.transition,
        gable: vals.gable,
        sidewall: vals.sidewall,
        valley: vals.valley,
        drip: vals.drip,
        calculatedValues: this.updateCalculatedValues(panelDetail, this.state.panelEntries, vals)
      });
    }
    else {
      this.setState({
        ...this.state,
        ridge: vals.ridge,
        hip: vals.hip,
        endwall: vals.endwall,
        transition: vals.transition,
        gable: vals.gable,
        sidewall: vals.sidewall,
        valley: vals.valley,
        drip: vals.drip,
      });
    }
  }

  async onPanelEntryDeleted(key: number) {
    // we need this to update the calculated values.
    const panelDetail = this.state.panelDetails.find((c: PanelDetail) => {
      return c.id === this.state.selectedDetailId;
    });

    const newEntries = this.state.panelEntries
      ? this.state.panelEntries.filter((c) => c.lengthInInches !== key) // filter removes the old detail row.
      : [];

    await this.setState({
      panelEntries: newEntries,
      calculatedValues: this.updateCalculatedValues(panelDetail, newEntries, this.state),
    });
  }

  onAddPanelEntryClicked(entry: IPanelsFormState) {
    if (
      entry !== undefined &&
      (entry.numPanels as number) > 0 &&
      ((entry.numFeet as number) > 0 || (entry.numInches as number) > 0)
    ) {
      const newInches = (entry?.numInches as number) % 12;
      console.log("newInches: " + newInches);
      const newFeet =
        +(entry?.numFeet as number) +
        (+(entry?.numInches as number) - newInches) / 12;
      console.log("newFeet: " + newFeet);
      const totalInches = newFeet * 12 + newInches;
      console.log("totalInches: " + totalInches);
      const detail: PanelOrderLine = {
        numPanels: entry.numPanels as number,
        numFeet: newFeet,
        numInches: newInches,
        lengthInInches: totalInches,
      };

      // We need to look for an existing "lengthInInches" match. If found
      // we want include those num panels to our new detail.  Then, we'll
      // remove the existing entry with matching "lengthInInches" and add
      // our new record.
      if (this.state.panelEntries) {
        const currentDetail = this.state.panelEntries.find(
          (c: PanelOrderLine) => {
            return c.lengthInInches === detail.lengthInInches;
          }
        );
        if (currentDetail) {
          detail.numPanels = +detail.numPanels + +currentDetail.numPanels;
        }
      }

      // we need this to update the calculated values.
      const panelDetail = this.state.panelDetails.find((c: PanelDetail) => {
        return c.id === this.state.selectedDetailId;
      });

      const newEntries = this.state.panelEntries
        ? this.state.panelEntries
          .filter((c) => c.lengthInInches !== detail.lengthInInches) // filter removes the old detail row.
          .concat(detail)
        : [detail];

      this.setState({
        panelEntries: newEntries,
        calculatedValues: this.updateCalculatedValues(panelDetail, newEntries, this.state),
      });
      this.setFirstFocus();
    }
  }

  onPanelProfileChanged(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      selectedPanelProfileId: +event.target.value,
      selectedDetailId: 0,
      selectedWeightProfileId: 0,
    });
  }

  onWeightProfileChanged(event: ChangeEvent<HTMLInputElement>) {
    console.log(+event.target.value);
    const detail = this.state.panelDetails.find((c: PanelDetail) => {
      return (
        c.panelProfileId === this.state.selectedPanelProfileId &&
        c.weightProfileId === +event.target.value
      );
    });

    this.setState({
      selectedWeightProfileId: +event.target.value,
      selectedDetailId: +(detail?.id as number),
      calculatedValues: this.updateCalculatedValues(
        detail,
        this.state.panelEntries,
        undefined
      ),
    });
  }

  updateCalculatedValues(
    detail: PanelDetail | undefined,
    panels: PanelOrderLine[],
    currentState: IQuoteDetailsState | IQuoteWorksheetState | undefined
  ): any {
    let curState = currentState ? currentState : this.state;

    if (detail) {
      console.log(detail);
      let vals = this.state.calculatedValues;


      const linearInches = Calculate.linearInches(panels);
      const squareFeet = Calculate.squareFeet(linearInches, detail.panelWidth);
      const linearFeet = Calculate.linearFeet(linearInches);
      // weightBySqFt kinda a bad name since it's not square feet used in the totalweight calculation.
      vals.totalWeight = Calculate.totalWeight(linearFeet, detail.weightBySqFt);
      vals.linearInches = linearInches;
      vals.linearFeet = Calculate.linearFeet(linearInches);
      vals.squareFeet = squareFeet;
      vals.screws = Calculate.screws(linearInches, detail.screwSpacing);
      vals.purlins = Calculate.purlins(detail, this.state.panelEntries);
      vals.panelScrews = Calculate.panelScrews(
        linearInches,
        detail.screwSpacing
      );
      vals.trimScrews = Calculate.trimScrews(
        vals,
        curState.ridge,
        curState.hip,
        curState.endwall,
        curState.transition,
        curState.gable,
        curState.sidewall
      );
      vals.lapScrews = Calculate.lapScrews(detail, panels);
      vals.sixInchPeelSeal = Calculate.sixInchPeelSeal(curState.hip, detail);
      vals.doubleBubble = Calculate.doubleBubble(squareFeet);
      vals.syntheticUnderlayment = Calculate.syntheticUnderlayment(squareFeet);
      vals.purlinScrews = Calculate.purlinScrews(vals.purlins, detail);
      vals.purlinNails = Calculate.purlinNails(vals.purlins, detail);
      vals.tackyTapePanelLaps = Calculate.tackyTapePanelLaps(
        vals.linearFeet,
        detail
      );
      vals.tackyTapeTrim = Calculate.tackyTapeTrim(
        curState.gable,
        curState.sidewall,
        detail
      );
      vals.expandingFoam = Calculate.expandingFoam(curState.valley, detail);
      vals.outsideClosures = Calculate.outsideClosures(
        curState.ridge,
        curState.endwall,
        curState.transition,
        detail
      );
      vals.insideClosures = Calculate.insideClosures(
        curState.drip,
        curState.transition,
        detail
      );
      vals.flex_o_vent = Calculate.flex_o_vent(this.state.ridge, detail);
      vals.zFlashing = Calculate.zFlashing(
        curState.ridge,
        curState.hip,
        curState.endwall,
        curState.transition,
        curState.gable,
        curState.sidewall
      );

      vals.panelStarters = Calculate.panelStarters(
        curState.valley,
        curState.transition
      );
      vals.eaveCleats = Calculate.eaveCleats(curState.gable, curState.drip);
      vals.pancakeScrews = Calculate.pancakeScrews(
        linearInches,
        detail.screwSpacing
      );
      vals.rivets = Calculate.rivets(curState.ridge, curState.sidewall);
      vals.clips = Calculate.clips(linearInches, detail);
      vals.titebond = Calculate.titebond(
        curState.ridge,
        curState.hip,
        curState.endwall,
        curState.transition,
        curState.gable,
        curState.sidewall
      );
      vals.tackTape = Calculate.tackTape(
        curState.ridge,
        curState.hip,
        curState.endwall,
        curState.transition,
        curState.gable,
        curState.sidewall,
        curState.valley
      );
      vals.peelStickUnderlayment = Calculate.peelStickUnderlayment(squareFeet);
      vals.stealthBondZFlashing = Calculate.stealthBondZFlashing(
        curState.ridge,
        curState.hip,
        curState.endwall,
        curState.transition,
        curState.gable,
        curState.sidewall
      );
      vals.stealthBondRingshank = Calculate.stealthBondRivets(
        curState.ridge,
        curState.sidewall
      );
      vals.stealthBondPancakes = Calculate.stealthBondPancakes(
        vals,
        curState.ridge,
        curState.hip,
        curState.endwall,
        curState.transition,
        curState.gable,
        curState.sidewall
      );
      vals.stealthBondRingshank = Calculate.stealthBondRingshank(vals.boards);

      console.log(vals);
      return vals;
    } else if (panels) {
      let vals = this.state.calculatedValues;
      const linearInches = Calculate.linearInches(panels);
      // weightBySqFt kinda a bad name since it's not square feet used in the totalweight calculation.
      vals.linearInches = linearInches;
      vals.linearFeet = Calculate.linearFeet(linearInches);
      return vals;
    } else {
      console.log(this.state.calculatedValues);
      return this.state.calculatedValues;
    }
  }

  // This method here get used to set initial focus on mount.  And, to reset the focus on the
  // add panels form after the add button is clicked.
  setFirstFocus() {
    const focusOn = document.getElementById("numPanels") as HTMLInputElement;
    focusOn?.focus();
    focusOn?.select();
  }

  componentDidMount() {
    this.getPanelDetails();
    this.setFirstFocus();
  }

  componentDidUpdate() {

  }
  render() {

    let quoteDetailsClassName = "";
    if (this.props.hideQuoteDetails) {      
      quoteDetailsClassName = "d-none";
    }

    let postMsgOnCopy = false;
    if (this.props.hideQuoteDetails) {
      if (window.location !== window.parent.location)
        postMsgOnCopy = true
    }    
    return (
      <Container fluid>
        <Row>
          <AddPanelsForm
            onAddPanelsClick={this.onAddPanelEntryClicked}
            onPanelLineDelete={this.onPanelEntryDeleted}
            hidePanelWeight={this.props.hideQuoteDetails}
            postMessageOnCopy={postMsgOnCopy}
            {...this.state}
          />
          <Col xs="12" sm="12" md="8" lg="9" className={quoteDetailsClassName}>
            <Row className="mb-2">
              <Col xs="6" sm="6" md="3">
                <Col xs="12">Select Panel Profile</Col>
                <Col xs="12">
                  <Input
                    type="select"
                    name="panelProfile"
                    id="panelProfile"
                    value={this.state.selectedPanelProfileId}
                    onChange={this.onPanelProfileChanged}
                  >
                    <option value="0">---</option>
                    {this.getPanelProfiles()}
                  </Input>
                </Col>
              </Col>
              <Col xs="6" sm="6" md="3">
                <Col xs="12">Select Gauge</Col>
                <Col xs="12">
                  <Input
                    type="select"
                    name="panelGauge"
                    id="panelGauge"
                    value={this.state.selectedWeightProfileId}
                    onChange={this.onWeightProfileChanged}
                  >
                    <option value="0">---</option>
                    {this.getWeightProfiles()}
                  </Input>
                </Col>
              </Col>
              <Col xs="4" sm="4" md="3" className="d-none d-md-block">
                <Col xs="12">Screw Spacing</Col>
                <Col xs="12">{this.getScrewSpacing()}</Col>
              </Col>
            </Row>
            <QuoteDetails
              {...this.state}
              roofCharacteristicsChanged={this.onRoofCharacteristicsChanged}
            />      
          </Col>
        </Row>
      </Container>
    );
  }

  getPanelProfiles(): any {
    return Array.from(
      new Set(this.state.panelDetails.map((c: PanelDetail) => c.panelProfileId))
    ).map((id: number) => {
      const item = this.state.panelDetails.find((c: PanelDetail) => {
        return c.panelProfileId === id;
      });
      return (
        <option value={id} key={"p" + id}>
          {item?.profileName}
        </option>
      );
    });
  }

  getScrewSpacing(): any {
    if (this.state.selectedDetailId === 0) return <React.Fragment />;

    const item = this.state.panelDetails.find(
      (c: PanelDetail) => c.id === this.state.selectedDetailId
    );
    return <React.Fragment>{item?.screwSpacing}&rdquo;</React.Fragment>;
  }

  getWeightProfiles(): any {
    var weightsForPanel = this.state.panelDetails.filter(
      (c: PanelDetail) => c.panelProfileId === this.state.selectedPanelProfileId
    );

    return Array.from(
      new Set(weightsForPanel.map((c: PanelDetail) => c.weightProfileId))
    ).map((id: number) => {
      const item = weightsForPanel.find((c: PanelDetail) => {
        return c.weightProfileId === id;
      });
      return (
        <option value={id} key={"w" + id}>
          {item?.gaugeName}
        </option>
      );
    });
  }

  getPanelDetails() {
    fetch(config.apiBaseUrl + "/panels")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ panelDetails: data });
      });
  }
}

export default QuoteWorksheetPage;
