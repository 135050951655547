import React from "react";
import { Col, Container, Row } from "reactstrap";


export function LoginPage(props: any) {  
  return (
    <Container>
      <Row>
        <Col>
          <h1>You are not logged in. </h1>
        </Col>
      </Row>
    </Container>
  );
}
