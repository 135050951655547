import React, { ChangeEvent } from "react";
import { Table, Input, Button } from "reactstrap"
import TcmQuotingService from "../../services/TcmQuotingService";
import { config } from "../../Config";
import { PanelDetail, PanelProfile, WeightProfile } from "../../types";

export interface IPanelWeightMatrixState {  
  selectedPanelProfileId?: number,
  selectedWeightProfileId?: number,
  newScrewSpacing?: number
}

export interface IPanelWeightMatrixProps {
  panelWeightMatrixProfiles: PanelDetail[],
  panelProfiles: PanelProfile[],
  weightProfiles: WeightProfile[],  
  refreshData: Function

}

export class PanelWeightMatrixTable extends React.Component<IPanelWeightMatrixProps, IPanelWeightMatrixState> {
  private tcmSvc: TcmQuotingService;

  constructor(props: IPanelWeightMatrixProps) {
    super(props);

    this.state = {      
      selectedPanelProfileId: 0,
      selectedWeightProfileId: 0,
      newScrewSpacing: 0
    } as IPanelWeightMatrixState;

    this.tcmSvc = new TcmQuotingService(config.apiBaseUrl);
    this.getPanelProfileOptionItems = this.getPanelProfileOptionItems.bind(this);
    this.getWeightProfileOptionItems = this.getWeightProfileOptionItems.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.addRecord = this.addRecord.bind(this);
  }

  onFieldChange(event: ChangeEvent<HTMLInputElement>) {
    // update the field.
    console.log(event.currentTarget.name + ": " + event.currentTarget.value)
    this.setState({[event.currentTarget.name]: event.currentTarget.value });
  }

  addRecord() {
    if ((this.state.selectedPanelProfileId === 0 || this.state.selectedPanelProfileId === undefined)
      || (this.state.selectedWeightProfileId === 0 || this.state.selectedWeightProfileId === undefined)
      || (this.state.newScrewSpacing === 0 || this.state.newScrewSpacing === undefined)) {
      alert("please selected a Panel, Weight and Screw Spacing.");
    } else {
      this.tcmSvc.createPanelWeightMatrixItem({
        panelProfileId: +this.state.selectedPanelProfileId,
        weightProfileId: +this.state.selectedWeightProfileId,
        screwSpacing: +this.state.newScrewSpacing
      }).then(() => {
        this.setState({ selectedPanelProfileId: 0, selectedWeightProfileId: 0, newScrewSpacing: 0 });
        this.props.refreshData();
      });
      }
  }

  deleteRecordById(id: number) {
    if (window.confirm("Are you sure you want to delete this?")) { 
      this.tcmSvc.deletePanelWeightMatrixItem(id)
        .then(() => this.props.refreshData());
    }
  }

  componentDidMount() {
    
  }

  getPanelWeightMatrixTableRows(): any {
    return this.props.panelWeightMatrixProfiles?.map((item) => {
      return (
        <tr key={"m"+item.id}>
          <td>{item.profileName}</td>
          <td>{item.panelWidth}"</td>
          <td>{item.boardSpacing}"</td>
          <td>{item.gaugeName}</td>
          <td>{item.weightBySqFt}</td>
          <td>{item.screwSpacing}"</td>
          <td>
            <Button onClick={() => this.deleteRecordById(item.id)}
              color="danger" size="sm">
              Delete
            </Button>
          </td>
        </tr>
      );
    });
  }

  getPanelProfileOptionItems() {
    return this.props.panelProfiles.map((item) => {
      return (<option key={"p"+item.id} value={item.id}>{item.profileName}</option>)
      });    
  }

  getWeightProfileOptionItems() {
    console.log(this.props.weightProfiles);
    return this.props.weightProfiles.map((item) => {
        return (<option key={"w"+item.id}  value={item.id}>{item.gaugeName}</option>)
      });    
  }
  
  render() {
    return (
      <Table striped={true} size="sm"> 
        <thead>
          <tr>
            <th scope="col">Panel Profile</th>
            <th scope="col">Panel Width</th>
            <th scope="col">Board Spacing</th>
            <th scope="col">Guage</th>
            <th scope="col">Weight By Sq.Ft.</th>
            <th scope="col">Screw Spacing</th>
            <th scope="col">&nbsp;</th>
          </tr>
          <tr>
            <th colSpan={3}>
              <Input
                type="select"
                name="selectedPanelProfileId"
                id="matrixPanelProfile"
                value={this.state.selectedPanelProfileId}
                    onChange={this.onFieldChange}>
                <option value="0">--- Select Panel ---</option>
                {this.getPanelProfileOptionItems()}
              </Input>
            </th>
            <th colSpan={2}><Input
              type="select"
              name="selectedWeightProfileId"
              id="matrixWeightProfile"
            value={this.state.selectedWeightProfileId}
                    onChange={this.onFieldChange}>
              <option value="0">--- Select Weight ---</option>
              {this.getWeightProfileOptionItems()}
            </Input></th>
            <th>
              <Input
                type="number"
                name="newScrewSpacing"
                min={0}
                step={.25}
                placeholder="Screw Spacing (inches)"
                onChange={this.onFieldChange}
                value={this.state.newScrewSpacing}
              />

            </th>
            <th><Button color="success" onClick={this.addRecord}>Add</Button></th>
          </tr>
        </thead>
        <tbody>
            {this.getPanelWeightMatrixTableRows()}
        </tbody>
      </Table>
    );
  }
}

export default PanelWeightMatrixTable;
