import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./scss/custom.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import QuoteWorksheetPage from "./pages/QuoteWorksheet";

var query = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
if (query.length > 0 && query[0].toLowerCase() === "embed=true") {  
  ReactDOM.render(
    <React.StrictMode>
      <QuoteWorksheetPage hideQuoteDetails />
    </React.StrictMode>,
    document.getElementById("root"));
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
