var graph = require('@microsoft/microsoft-graph-client');

function getAuthenticatedClient(accessToken : string) {
    // Initialize Graph client 
    const client = graph.Client.init({
        authProvider: (done: any) => {
            done(null, accessToken);
        }
    });
    return client;
}

export async function getUserDetails(accessToken: string) {
    const client = getAuthenticatedClient(accessToken);
    const user = await client.api('/me').get();
    return user;
}

export async function getUserPhoto(accessToken: string) {
    const client = getAuthenticatedClient(accessToken);
    const photo = await client.api('/me/photo/$value').get();
    return photo;
}